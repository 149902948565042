<template>
  <IconBase :width="width" :height="height" :iconName="icon" :title="title">
    <component :is="iconComponent" v-bind="$attrs" :small="isSmall"></component>
  </IconBase>
</template>

<script>
import IconBase from "@/components/icons/icon-base";
const IconDevice = () => import("@/components/icons/icon-device");

const iconComponents = {
  device: IconDevice
};

export default {
  name: "PortalIcon",
  inheritAttrs: false,
  components: { IconBase, IconDevice },
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: 14
    },
    height: {
      type: [String, Number],
      default: 14
    },
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      iconComponent: null
    };
  },
  watch: {
    icon: {
      immediate: true,
      handler(icon) {
        if (icon in iconComponents)
          iconComponents[icon]().then((module) => {
            this.iconComponent = module.default;
          });
      }
    }
  },
  computed: {
    isSmall() {
      return Number(this.width) <= 25 || Number(this.height) <= 25;
    }
  }
};
</script>
